.burger-menu-container .bm-menu {
  background: #423fcc; /* Background color */
  padding: 2.5em 1.5em 0;
  font-size: 1.15em; /* Font size */
}

.burger-menu-container .burger-menu-item {
  display: block;
  text-decoration: none;
  color: #ffffff;
  margin-bottom: 15px; /* Increased margin for better spacing */
  padding: 0.5em 1em; /* Add padding for clickable area */
  border-radius: 5px; /* Rounded corners */
  transition:
    color 0.2s,
    background 0.2s; /* Smooth transitions */
}

.burger-menu-container .burger-menu-item:hover {
  color: #eeeeee;
  background-color: #5753d6; /* Slight background color change on hover */
}

.burger-menu-container .bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
  display: none;
}

.burger-menu-container .bm-burger-bars {
  background: #ffffff; /* Bar color */
}

.burger-menu-container .bm-cross-button {
  height: 24px;
  width: 24px;
}

.burger-menu-container .bm-cross {
  background: white; /* Set the color of the cross */
  width: 3px;
  height: 20px;
}

.burger-menu-container .bm-morph-shape {
  fill: #ffffff; /* Morph shape fill color */
}

.burger-menu-container .bm-item-list {
  color: #b8b7ad; /* Item list color */
}

.burger-menu-container .bm-overlay {
  background: rgba(0, 0, 0, 0.3); /* Overlay color */
  top: 0;
  left: 0;
}

/* Responsive styles */
@media (max-width: 991px) {
  .burger-menu-container .bm-burger-button {
    display: block; /* Show burger button on mobile */
  }
}
