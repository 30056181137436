.employee-detail-hours-worked-chart {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee2e6;
  padding: 15px;
  min-width: 400px;
  height: 100%;
  width: 40%;
  overflow: hidden;
}

.employee-detail-hours-worked-chart .chart-title {
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
  background-color: white;
  padding: 10px 0;
  color: #333b46;
  font-size: 1.5rem;
}

.employee-detail-hours-worked-chart .chart-container {
  display: flex; /* Align items horizontally */
  align-items: center; /* Center align the button and chart */
  justify-content: space-between; /* Space buttons and chart evenly */
}

.employee-detail-hours-worked-chart .pan-button {
  background-color: transparent; /* Make background transparent */
  border: none; /* Remove default button border */
  cursor: pointer; /* Pointer cursor on hover */
  padding: 10px; /* Add padding around the icon */
  transition: opacity 0.3s; /* Smooth opacity transition */
}

.employee-detail-hours-worked-chart .pan-button:disabled {
  opacity: 0.5; /* Fade out disabled buttons */
  cursor: not-allowed; /* Change cursor for disabled buttons */
}

.employee-detail-hours-worked-chart .pan-button:hover:not(:disabled) {
  opacity: 0.7; /* Slightly fade on hover */
}

.employee-detail-hours-worked-chart .arrow-icon {
  width: 70px; /* Set width of arrow icons */
  height: 70px; /* Set height of arrow icons */
  filter: invert(85%) sepia(18%) saturate(108%) hue-rotate(131deg)
    brightness(83%) contrast(88%);
}

.employee-detail-hours-worked-chart .scrollable-chart-container {
  overflow-x: auto;
  width: 100%; /* Ensure the container uses full width */
  padding-bottom: 10px;
  display: flex;
  justify-content: center; /* Center align the chart */
}

.employee-detail-hours-worked-chart .view-more-button {
  display: block; /* Make the button a block element */
  background-color: #423fcc; /* Button background color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Padding */
  margin: 10px auto; /* Center the button with auto margins */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color transition */
  text-align: center; /* Center text within the button */
  width: fit-content; /* Adjust width to fit content */
}

.employee-detail-hours-worked-chart .view-more-button:hover {
  background-color: #2523a1; /* Darker blue on hover */
}

.employee-detail-hours-worked-chart .view-more-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
  opacity: 0.6;
}

@media (max-width: 1541px) {
  .employee-detail-hours-worked-chart {
    width: 100%;
  }
}

/* Media queries for responsiveness */
@media (max-width: 991px) {
  .employee-detail-hours-worked-chart {
    width: 100%;
    min-width: 0px;
  }

  .employee-detail-hours-worked-chart .pan-button {
    display: none;
  }
}
