.upcoming-gigs {
  display: flex;
  width: 400px;
}

.upcoming-gigs .gigs-card {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: "Arial", sans-serif;
  overflow: auto;
  max-height: 450px;
}

.upcoming-gigs .error-message {
  color: #7c7c7c;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}

.upcoming-gigs .gigs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.upcoming-gigs .gigs-header h2 {
  padding-top: 20px;
  font-size: 1.2rem;
  color: #333b46;
  margin-left: 10px;
}

.upcoming-gigs .navigate-gig-button {
  padding: 8px 12px;
  background-color: #6d6d6d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 5px;
}

.upcoming-gigs .navigate-gig-button:hover {
  background-color: #585858;
}

.upcoming-gigs .gig-list {
  list-style-type: none;
  padding: 20px;
  margin: 0;
}

.upcoming-gigs .gig-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s;
}

.upcoming-gigs .gig-item:last-child {
  border-bottom: none;
}

.upcoming-gigs .gig-item:hover {
  background-color: #f0f0f0;
}

.upcoming-gigs .gig-details {
  flex-grow: 1;
  color: #333;
}

.upcoming-gigs .gig-details strong {
  display: block;
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 4px;
}

.upcoming-gigs .gig-date {
  font-size: 0.9rem;
  font-style: italic;
}

.upcoming-gigs .gig-date.today {
  color: #6e6e6e;
  font-weight: bold;
}

.upcoming-gigs .gig-date.future {
  color: #6e6e6e;
  font-weight: bold;
}

.upcoming-gigs .gig-date.past {
  color: #d9534f;
  text-decoration: line-through;
}

.upcoming-gigs .gig-status {
  font-size: 0.9rem;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  border-radius: 12px;
  padding: 2px 8px;
  white-space: nowrap;
}

/* Gradient backgrounds for different statuses */
.upcoming-gigs .gig-status.scheduled {
  background: linear-gradient(90deg, #007bff, #0056b3); /* Blue for scheduled */
}

.upcoming-gigs .gig-status.in-progress {
  background: linear-gradient(
    90deg,
    #ffc107,
    #e0a800
  ); /* Yellow for in progress */
}

.upcoming-gigs .gig-status.completed {
  background: linear-gradient(
    90deg,
    #28a745,
    #1e7e34
  ); /* Green for completed */
}

.upcoming-gigs .gig-status.cancelled {
  background: linear-gradient(90deg, #d9534f, #c82333); /* Red for canceled */
}

@media (max-width: 991px) {
  .upcoming-gigs {
    width: 100%;
  }

  .upcoming-gigs .gigs-header h2 {
    font-size: 1.2rem;
  }
}
