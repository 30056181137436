.settings-user-info-card {
  width: 25%;
  min-width: 400px;
}

.settings-user-info-card .user-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee2e6;
  padding: 15px;
  overflow: hidden;
}

.settings-user-info-card .user-card h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #343a40;
}

.settings-user-info-card .user-info {
  line-height: 1.6;
  color: #495057;
}

.settings-user-info-card .user-info p {
  margin: 5px 0;
}

.settings-user-info-card .delete-section {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.settings-user-info-card .cancel-subscription-section {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.settings-user-info-card .delete-button {
  padding: 10px 20px;
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  transition: background-color 0.3s ease;
}

.settings-user-info-card .cancel-subscription-button {
  padding: 10px 20px;
  background-color: #999999;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  transition: background-color 0.3s ease;
}

.settings-user-info-card .delete-button:hover {
  background-color: #ff2a2a;
}

.settings-user-info-card .cancel-subscription-button:hover {
  background-color: #666666;
}

.settings-user-info-card .warning-section {
  background-color: #fff4e6;
  padding: 15px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ff7a7a;
}

.settings-user-info-card .warning-text {
  color: #d9534f;
  font-size: 14px;
}

.settings-user-info-card .confirmation-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.settings-user-info-card .confirm-delete,
.settings-user-info-card .cancel-delete {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.settings-user-info-card .confirm-cancel-subscription,
.settings-user-info-card .cancel-cancel-subscription {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.settings-user-info-card .confirm-delete {
  background-color: #d9534f;
  color: white;
}

.settings-user-info-card .confirm-cancel-subscription {
  background-color: #d9534f;
  color: white;
}

.settings-user-info-card .cancel-delete {
  background-color: #ccc;
  color: white;
}
.settings-user-info-card .cancel-cancel-subscription {
  background-color: #ccc;
  color: white;
}

.settings-user-info-card .confirm-delete:hover {
  background-color: #c9302c;
}
.settings-user-info-card .confirm-cancel-subscription:hover {
  background-color: #c9302c;
}

.settings-user-info-card .cancel-delete:hover {
  background-color: #999;
}
.settings-user-info-card .cancel-cancel-subscription:hover {
  background-color: #999;
}

@media (max-width: 1541px) {
  .settings-user-info-card {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .settings-user-info-card {
    width: 100%;
    min-width: 0;
  }
}
