.onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboarding .banner {
  z-index: 1;
}

.onboarding .banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.onboarding .onboarding-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  z-index: 2;
  padding: 20px;
  width: 100%;
}

.onboarding .logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  z-index: 10;
}

.onboarding .onboarding-container {
  width: auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 5;
  margin-top: 100px;
  margin-bottom: 100px;
}

.onboarding .onboarding-container header {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  position: relative;
}

.onboarding .onboarding-container header::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 3px;
  width: 40px;
  border-radius: 4px;
  background-color: #4070f4;
}

.onboarding .onboarding-container h2 {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-top: 25px;
}

.onboarding .onboarding-container p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-top: 10px;
}

.onboarding .onboarding-container a {
  color: #4070f4;
  font-weight: bold;
  text-decoration: none;
}

.onboarding .onboarding-container a:hover {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .onboarding .onboarding-container {
    width: 100%;
    margin-top: 20%;
  }
}

@media (max-width: 280px) {
  .onboarding .onboarding-text {
    margin: 1rem;
  }

  .onboarding h1 {
    font-size: 1.2rem;
  }

  .onboarding .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80px;
  }

  .onboarding .cards {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .onboarding .card {
    transform: scale(0.8);
    margin-bottom: 1rem;
  }

  .onboarding .cards .card.active {
    transform: scale(0.8);
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .onboarding .onboarding-text {
    margin: 20px;
  }

  .onboarding .cards {
    display: flex;
    flex-direction: column;
  }

  .onboarding .card {
    margin-bottom: 1rem;
  }

  .onboarding .cards .card.active {
    transform: scale(1);
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .onboarding .cards {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }

  .onboarding .card {
    margin-bottom: 1rem;
  }

  .onboarding .cards .card.active {
    transform: scale(1);
  }

  .onboarding .onboarding-container {
    width: 110%;
  }
}

@media (min-width: 414px) and (max-width: 768px) {
  .onboarding .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .onboarding .cards .card.active {
    transform: scale(1);
  }

  .onboarding .cards {
    padding-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1046px) {
  .onboarding .cards {
    display: flex;
  }

  .onboarding .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .onboarding .cards .card.active {
    transform: scale(1);
  }
}

.onboarding .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/*ENDING HOME PAGE*/
