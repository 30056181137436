.employee-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid transparent;
  width: 20%;
  height: 300px;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}

.employee-status .card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #2c3e50;
  font-weight: bold;
}

.employee-status .status-message {
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
}

.employee-status .clocked-in {
  color: #27ae60;
  font-weight: 600;
}

.employee-status .clocked-out {
  color: #e74c3c;
  font-weight: 600;
}

@media (max-width: 1541px) {
  .employee-status {
    width: 100%;
  }
}
