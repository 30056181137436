.settings {
  background-color: #eceff6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.settings .content {
  margin-top: 20px;
  margin-left: 90px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 991px) {
  .settings .content {
    margin: 20px;
    justify-content: center;
  }
}
