.pricing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-page .banner {
  z-index: 1;
}

.pricing-page .banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.pricing-page .pricing-page-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  z-index: 2;
  padding: 20px;
  width: 100%;
}

.pricing-page .logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  z-index: 10;
}

.pricing-page .pricing-container {
  width: auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 5;
  margin-top: 100px;
  margin-bottom: 100px;
}

.pricing-page .pricing-container header {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  position: relative;
}

.pricing-page .pricing-container header::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 3px;
  width: 40px;
  border-radius: 4px;
  background-color: #4070f4;
}

.pricing-page .pricing-container h2 {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-top: 25px;
}

.pricing-page .pricing-container p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-top: 10px;
}

.pricing-page .pricing-container a {
  color: #4070f4;
  font-weight: bold;
  text-decoration: none;
}

.pricing-page .pricing-container a:hover {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .pricing-page .pricing-container {
    width: 100%;
    margin-top: 20%;
  }
}

/*Pricing*/
.pricing-page .cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 60px;
  margin-bottom: 50px;
}

.pricing-page .card {
  background: #fff;
  color: hsl(233, 13%, 49%);
  border-radius: 0.8rem;
}

.pricing-page .cards .card.active {
  background: linear-gradient(
    135deg,
    rgba(48, 104, 227, 1) 0%,
    rgb(51, 65, 209) 100%
  );
  color: #fff;
  display: flex;
  align-items: center;
  transform: scale(1.1);
  z-index: 1;
}

.pricing-page .pricing-text {
  margin: 2.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.pricing-page .pack {
  font-size: 1.5rem;
  font-weight: bold;
}

.pricing-page .price {
  font-size: 3rem;
  color: hsl(232, 13%, 33%);
  padding-bottom: 2rem;
}

.pricing-page .bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.582);
  padding: 1rem 0;
}

.pricing-page .card.active .price {
  color: #fff;
}

.pricing-page .pricing-btn {
  margin-top: 1rem;
  height: 2.6rem;
  width: 13.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(48, 104, 227, 1) 0%,
    rgba(51, 65, 209, 1) 100%
  );
  color: #fff;
  outline: none;
  border: 0;
  font-weight: bold;
  cursor: pointer;
}

.pricing-page .active-btn {
  margin-top: 1rem;
  height: 2.6rem;
  width: 13.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgb(65, 121, 243) 0%,
    rgb(56, 72, 252) 100%
  );
  color: #fff;
  outline: none;
  border: 0;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 280px) {
  .pricing-page .pricing-text {
    margin: 1rem;
  }

  .pricing-page h1 {
    font-size: 1.2rem;
  }

  .pricing-page .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80px;
  }

  .pricing-page .cards {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .pricing-page .card {
    transform: scale(0.8);
    margin-bottom: 1rem;
  }

  .pricing-page .cards .card.active {
    transform: scale(0.8);
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .pricing-page .pricing-text {
    margin: 20px;
  }

  .pricing-page .cards {
    display: flex;
    flex-direction: column;
  }

  .pricing-page .card {
    margin-bottom: 1rem;
  }

  .pricing-page .cards .card.active {
    transform: scale(1);
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .pricing-page .cards {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }

  .pricing-page .card {
    margin-bottom: 1rem;
  }

  .pricing-page .cards .card.active {
    transform: scale(1);
  }

  .pricing-page .pricing-container {
    width: 110%;
  }
}

@media (min-width: 414px) and (max-width: 768px) {
  .pricing-page .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .pricing-page .cards .card.active {
    transform: scale(1);
  }

  .pricing-page .cards {
    padding-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1046px) {
  .pricing-page .cards {
    display: flex;
  }

  .pricing-page .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .pricing-page .cards .card.active {
    transform: scale(1);
  }
}

.pricing-page .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/*ENDING HOME PAGE*/
