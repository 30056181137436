.employee-attendance-details-table {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.employee-attendance-details-table .calendar-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.employee-attendance-details-table .custom-calendar {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.employee-attendance-details-table .no-records {
  text-align: center;
  color: #666;
}

.employee-attendance-details-table .attendance-table-container {
  width: 100%;
  overflow-x: auto;
}

.employee-attendance-details-table .attendance-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: "Arial", sans-serif;
}

.employee-attendance-details-table .attendance-table th,
.employee-attendance-details-table .attendance-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
}

.employee-attendance-details-table .attendance-table th {
  background-color: #dee2e6;
  color: #333b46;
}

.employee-attendance-details-table .attendance-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.employee-attendance-details-table .attendance-table tr:hover {
  background-color: #ddd;
}

.employee-attendance-details-table .attendance-table td {
  color: #555;
}

.employee-attendance-details-table .total-hours {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: #464646;
  margin: 25px;
}

.employee-attendance-details-table .attendance-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #464646;
  text-align: center;
}

.employee-attendance-details-table .back-button {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  color: #464646;
  font-weight: bold;
  transition: all 0.3s ease;
}

.employee-attendance-details-table .back-button:hover {
  color: #000000;
}

.employee-attendance-details-table .back-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  filter: invert(27%) sepia(0%) saturate(13%) hue-rotate(200deg) brightness(97%)
    contrast(93%);
}

.employee-attendance-details-table .map-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.employee-attendance-details-table .map-modal-content {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
}

.employee-attendance-details-table .close-modal {
  background-color: #ff4e4e;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.employee-attendance-details-table .close-modal:hover {
  background-color: #ff2e2e;
}
