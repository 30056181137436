/* ===== Google Font Import - Poppins ===== */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

.login * {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100svh;
  padding: 20px;
}

.login .logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
}

.login .container {
  position: relative;
  max-width: 400px;
  width: 100%;
  border-radius: 6px;
  padding: 30px;
  margin: 0 15px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.login .container header {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.login .container header::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 3px;
  width: 27px;
  border-radius: 8px;
  background-color: #4070f4;
}

.login .container form {
  position: relative;
  margin-top: 16px;
  background-color: #fff;
  overflow: hidden;
}

.login .container form .fields {
  display: flex;
  flex-direction: column; /* Changed to column layout */
  align-items: flex-start; /* Align items to the start */
}

.login form .fields .input-field {
  display: flex;
  width: 100%; /* Full width for input fields */
  flex-direction: column;
  margin: 4px 0;
}

.login .input-field label {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
}

.login .input-field input {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 15px;
  height: 42px;
  margin: 8px 0;
}

.login .input-field input:focus {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
}

.login .container form button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  margin: 25px auto;
  background-color: #4070f4;
  transition: all 0.3s linear;
  cursor: pointer;
}

.login .container form .btnText {
  font-size: 14px;
  font-weight: 400;
}

.login form button:hover {
  background-color: #265df2;
}

.login form button i {
  margin: 0 6px;
}

.login .signup-prompt {
  text-align: center;
  margin-top: 15px;
}

.login .signup-prompt p {
  font-size: 14px;
  color: #555; /* Change to your preferred color */
}

.login .signup-prompt a {
  color: #4070f4; /* Link color */
  text-decoration: none;
  font-weight: bold;
}

.login .signup-prompt a:hover {
  text-decoration: underline;
}

@media (max-width: 750px) {
  .login {
    align-items: flex-start;
  }

  .login .container {
    margin-top: 90px;
  }

  .login .container form {
    overflow-y: scroll;
  }

  .login .container form::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 550px) {
  .login form .fields .input-field {
    width: 100%; /* Ensured full width for smaller screens */
  }
}
