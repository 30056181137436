.overview-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;
}

/* Styling for chat card */
.overview-chat .chat-card {
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 4px;
  overflow: auto;
  max-height: 450px;
}

/* Header section */
.overview-chat .chat-header {
  display: flex;
  text-align: left;
  padding: 10px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  flex-direction: column;
}

.overview-chat .chat-header-bottom {
  display: flex;
  padding: 10px;
  flex-direction: row;
}

.overview-chat .chat-header h2 {
  font-size: 1.2rem;
  color: #333b46;
  margin-left: 10px;
  padding-top: 20px;
}

.overview-chat h3 {
  font-size: 1.2rem;
}

/* Employee List Section */
.overview-chat .employee-list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Employee item styles */
.overview-chat .employee-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.overview-chat .employee-item:hover {
  background-color: #f0f0f0;
  transform: scale(1.02); /* Hover effect: Slight scale */
}

.overview-chat .employee-item.active {
  background-color: #007bff;
  color: #fff;
}

/* Chat Section */
.overview-chat .chat-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.no-messages {
  font-size: 16px;
  text-align: center;
  padding: 20px;
}

/* Back Button Container */
.overview-chat .back-container {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.overview-chat .back-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #007bff;
  margin-right: 10px;
}

.overview-chat .back-button img {
  width: 30px;
  height: 30px;

  transition: transform 0.3s ease;
}

.overview-chat .back-button:hover img {
  transform: scale(1.1);
}

/* Chat Messages List */
.overview-chat .messages-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.overview-chat .message {
  max-width: 70%;
  padding: 12px 16px;
  margin: 8px 0;
  border-radius: 8px;
  font-size: 1rem;
  word-wrap: break-word;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.overview-chat .message.company-message {
  background-color: #007bff;
  color: white;
  align-self: flex-end; /* Align company messages to the right */
}

.overview-chat .message.employee-message {
  background-color: #f1f1f1;
  color: #333;
  align-self: flex-start; /* Align employee messages to the left */
}

.overview-chat .message.employee-message:hover {
  background-color: #dbdada;
}

.overview-chat .message.company-message:hover {
  background-color: #006ce0;
}

/* Input Area */
.overview-chat .message-input-container {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}

.overview-chat .message-input {
  width: 100%;
  height: 50px;
  padding: 12px;
  border-radius: 30px;
  border: 2px solid #ddd;
  resize: none;
  margin-bottom: 15px;
  font-size: 1rem;
}

.overview-chat .send-button {
  align-self: flex-end;
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.overview-chat .send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.overview-chat .send-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 991px) {
  .overview-chat {
    width: 100%;
    min-width: 0;
  }

  .overview-chat .chat-header h2,
  .overview-chat .chat-header h3 {
    font-size: 1.2rem;
  }

  .overview-chat .message-input {
    height: 40px;
  }

  .overview-chat .send-button {
    font-size: 0.9rem;
  }
}
