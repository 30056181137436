.gigs-calendar {
  display: flex;
  flex-direction: row; /* Changed from column to row */
  height: 80svh;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.gigs-calendar .sidebar {
  display: flex;
  flex-direction: column; /* Ensure elements are stacked vertically */
  justify-content: flex-start;
  padding: 15px;
  gap: 20px; /* Add space between calendar and button */
}

.gigs-calendar .sidebar .add-button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #55ad00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.gigs-calendar .sidebar .add-button:hover {
  background-color: #3e8100;
}

.gigs-calendar .edit-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.gigs-calendar .edit-button:hover {
  background-color: #45a049;
}

.gigs-calendar .gigs-list {
  flex-grow: 1;
  padding-left: 20px;
  max-height: 100%;
  overflow-y: auto;
}

.gigs-calendar .gigs-list h3 {
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  color: #343a40;
}

.gigs-calendar .gigs-table {
  width: 100%;
  border-collapse: collapse;
}

.gigs-calendar .gigs-table th,
.gigs-calendar .gigs-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.gigs-calendar .gigs-table th {
  background-color: #dee2e6;
  font-weight: bold;
  color: #333b46;
}

.gigs-calendar .gigs-table tr:hover {
  background-color: #f1f1f1;
}

.gigs-calendar .modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gigs-calendar .modal {
  background-color: white;
  padding: 50px;
  border-radius: 4px;
  width: 50%;
  max-height: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gigs-calendar .modal h2 {
  margin-top: 20px;
  font-family: Arial, sans-serif;
  font-size: 30px;
  margin-bottom: 20px;
}

.gigs-calendar form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
}

.gigs-calendar .label-and-input-pair {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.gigs-calendar label {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: #ffffff;
  width: fit-content;
}

.gigs-calendar input,
.gigs-calendar .modal select {
  padding: 11px 10px;
  font-size: 1.5rem;
  border: 1px #7c7c7c solid;
  border-radius: 5px;
  background: #ffffff;
  height: 53px;
}

.gigs-calendar .modal textarea {
  padding: 11px 10px;
  font-size: 1.5rem;
  border: 1px #7c7c7c solid;
  border-radius: 5px;
  background: #ffffff;
  height: 170px;
}

.gigs-calendar .modal button[type="submit"] {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 30%;
}

.gigs-calendar .modal button[type="submit"]:hover {
  background-color: #0056b3;
}

.gigs-calendar .close-x {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.gigs-calendar .close-x:hover {
  color: red;
}

/* Style the assigned employees section */
.gigs-calendar .assigned-employees {
  display: flex;
  flex-direction: column;
}

.gigs-calendar .assigned-employee {
  background-color: #fcfcfc;
  padding: 8px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  color: #495057;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.gigs-calendar .assigned-employee:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gigs-calendar .assigned-employee span {
  margin-right: 10px;
}

.gigs-calendar .remove-employee {
  background: transparent;
  border: none;
  color: #d9534f;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  transition: color 0.3s ease;
}

.gigs-calendar .remove-employee:hover {
  color: #c9302c;
}

.gigs-calendar .assigned-employee + .assigned-employee {
  margin-top: 10px;
}

.gigs-calendar .assigned-employee.no-assigned {
  background-color: #f1f1f1;
  color: #6c757d;
  font-style: italic;
  padding: 12px 20px;
  border-radius: 25px;
  box-shadow: none;
}

.gigs-calendar .employee-select {
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.gigs-calendar .employee-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.gigs-calendar .employee-select option {
  font-size: 1rem;
  padding: 8px;
}

.gigs-calendar p {
  text-align: center;
}

@media (max-width: 991px) {
  .gigs-calendar {
    flex-direction: column;
    height: 100%;
  }

  .gigs-calendar .sidebar {
    width: 100%;
    padding: 10px;
    gap: 15px;
    align-items: center;
  }

  .gigs-calendar .gigs-list {
    padding-left: 0;
  }

  .gigs-calendar .modal {
    width: 90%;
    padding: 5px;
  }

  .gigs-calendar .modal h2 {
    font-size: 25px;
  }

  .gigs-calendar label {
    font-size: 1rem;
  }
}
