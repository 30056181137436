.FAQ {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FAQ .banner {
  z-index: 1;
}

.FAQ .banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.FAQ .FAQ-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  z-index: 2;
  padding: 20px;
  width: 100%;
}

.FAQ .logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  z-index: 10;
}

.FAQ .FAQ-container {
  width: 60%;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 5;
  margin-top: 100px;
  margin-bottom: 100px;
}

.FAQ .FAQ-container header {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  position: relative;
}

.FAQ .FAQ-container header::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 3px;
  width: 40px;
  border-radius: 4px;
  background-color: #4070f4;
}

.FAQ .faq-question {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  color: #333;
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
  padding: 10px 0;
}

.FAQ .faq-question:hover {
  color: #4070f4;
}

.FAQ .faq-toggle-icon {
  font-size: 24px;
  font-weight: bold;
  color: #4070f4;
}

.FAQ .faq-answer {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  transition: max-height 0.3s ease-out;
  max-height: 200px;
  overflow: hidden;
}

.FAQ .faq-separator {
  border-bottom: 1px solid #ddd;
  margin: 15px 0;
}

.FAQ .FAQ-container a {
  color: #4070f4;
  font-weight: bold;
  text-decoration: none;
}

.FAQ .FAQ-container a:hover {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .FAQ .FAQ-container {
    width: 100%;
    margin-top: 20%;
  }

  .FAQ .faq-question {
    font-size: 20px;
    margin-top: 20px;
  }

  .FAQ .faq-answer {
    font-size: 14px;
  }
}
