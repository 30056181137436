/* Styles for the DashboardNavbar component */
.dashboard-navbar {
  position: relative; /* Ensure proper positioning of header and menu */
}

/* Header styles */
.dashboard-navbar .navbar-header {
  background-color: #ffffff; /* Header background color */
  color: #333b46;
  padding: 1em; /* Space inside header */
  font-size: 1.5em; /* Font size for the header */
  z-index: 2; /* Higher z-index to ensure it overlaps */
  position: relative;
  margin-left: 90px;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.dashboard-navbar .navbar-links-logo {
  height: 35px;
  width: 35px;
  left: 17px;
  top: 22px;
  z-index: 3;
  position: fixed;
  display: flex;
  filter: invert(54%) sepia(6%) saturate(1198%) hue-rotate(175deg)
    brightness(95%) contrast(92%);
}

/* Scope burger menu styles to the DashboardNavbar */
.dashboard-navbar .bm-menu {
  background: #333b46;
  font-size: 1.15em;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Add shadow to the menu */
  padding: 1.5em; /* Increase padding for a more spacious feel */
  height: 100svh;
  display: flex;
  flex-direction: column;
}

.dashboard-navbar .bm-overlay {
  top: 0px;
}

.dashboard-navbar .bm-menu-wrap {
  top: 0px;
}

/* Apply same styles as navbar-item */
.dashboard-navbar .bm-item {
  display: block;
  text-decoration: none;
  color: #ffffff;
  margin-bottom: 15px; /* Increased margin for better spacing */
  padding: 0.5em 1em; /* Add padding for clickable area */
  border-radius: 5px; /* Rounded corners */
  transition:
    color 0.2s,
    background 0.2s; /* Smooth transitions */
}

/* Hover effect for burger menu items, same as navbar items */
.dashboard-navbar .bm-item:hover {
  color: #eeeeee;
  background-color: #535f6e; /* Slight background color change on hover */
}

.dashboard-navbar .navbar-links {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 70px; /* Fixed width of the navbar */
  height: 100svh; /* 100% of the viewport height */
  background: #ffffff;
  padding-top: 100px;
  z-index: 1;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  align-items: center; /* Centers items horizontally in the column */
  gap: 35px;
}

/* Navbar item styles */
.dashboard-navbar .navbar-item img {
  width: 22px;
  height: 22px;
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
  filter: invert(54%) sepia(6%) saturate(1198%) hue-rotate(175deg)
    brightness(95%) contrast(92%);
  cursor: pointer;
}

.dashboard-navbar .navbar-item:hover {
  transform: scale(1.2);
}

/* Scope burger button styles to the DashboardNavbar */
.dashboard-navbar .bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 40px;
  top: 45px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 3;
  display: none;
}

.dashboard-navbar .bm-burger-bars {
  background: #333b46;
  border-radius: 2px; /* Slightly rounded bars */
}

.dashboard-navbar .bm-cross {
  background: white; /* Set the color of the cross */
  width: 3px;
  height: 20px;
}

/* Responsive styles */
@media (max-width: 991px) {
  .dashboard-navbar .navbar-links {
    display: none;
  }

  .dashboard-navbar .bm-burger-button {
    display: block;
  }

  .dashboard-navbar .navbar-links-logo {
    display: none;
  }

  .dashboard-navbar .navbar-header {
    text-align: center;
    margin-left: 20px;
  }
}
