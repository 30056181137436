/* ===== Google Font Import - Poppins ===== */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

.register * {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100svh;
  padding: 20px;
}

.register .logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
}

.register .container {
  position: relative;
  max-width: 900px;
  width: 100%;
  border-radius: 6px;
  padding: 30px;
  margin: 0 15px;
  margin-top: 45px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  height: 78svh;
}

.register .container header {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.register .container header::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 3px;
  width: 27px;
  border-radius: 8px;
  background-color: #4070f4;
}

.register .container form {
  position: relative;
  margin-top: 16px;
  min-height: 85%;
  background-color: #fff;
  overflow: auto;
  justify-content: center;
  align-items: center;
}

.register .container form .form {
  position: absolute;
  background-color: #fff;
  transition: 0.3s ease;
  width: 100%;
}

.register .container form .form.second {
  opacity: 0;
  pointer-events: none;
  transform: translateX(100%);
}

.register form.secActive .form.second {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(0);
}

.register form.secActive .form.first {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);
}

.register .container form .title {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  margin: 6px 0;
  color: #333;
}

.register .container form .fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.register form .fields .input-field {
  display: flex;
  width: calc(100% / 3 - 15px);
  flex-direction: column;
  margin: 4px 0;
}

.register .input-field label {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
}

.register .input-field input,
select {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 15px;
  height: 42px;
  margin: 8px 0;
}

.register .input-field input :focus,
.register .input-field select:focus {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
}

.register .input-field select,
.register .input-field input[type="date"] {
  color: #707070;
}

.register .input-field input[type="date"]:valid {
  color: #333;
}

.register .container form button,
.nextBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  margin: 25px 0;
  background-color: #4070f4;
  transition: all 0.3s linear;
  cursor: pointer;
  margin: 25px auto;
}

.register .container form .btnText {
  font-size: 14px;
  font-weight: 400;
}

.register form button:hover {
  background-color: #265df2;
}

.register form .backBtn i {
  transform: rotate(180deg);
}

.register form .buttons {
  display: flex;
  align-items: center;
}

.register form .buttons button,
.backBtn {
  margin-right: 14px;
}

.register .sign-in-prompt {
  text-align: center;
  margin-top: 15px;
}

.register .sign-in-prompt p {
  font-size: 14px;
  color: #555;
}

.register .sign-in-prompt a {
  color: #4070f4;
  /* Link color */
  text-decoration: none;
  font-weight: bold;
}

.register .sign-in-prompt a:hover {
  text-decoration: underline;
}

/*Terms*/
.register form .termsAndConditions {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  margin-top: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.register form .termsAndConditions label {
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  flex-wrap: wrap; /* Allow label text to wrap */
  text-align: center; /* Ensure text inside label is centered */
}

.register form .termsAndConditions input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.register form .termsAndConditions a {
  color: #4070f4;
  text-decoration: none;
  margin-left: 5px;
}

.register form .termsAndConditions a:hover {
  text-decoration: underline;
}

.register form .termsAndConditions label span {
  margin-left: 5px; /* Small gap between 'the' and 'Terms' */
}

@media (max-width: 750px) {
  .register {
    align-items: flex-start;
  }

  .register .container {
    margin-top: 90px;
  }

  .register .container form {
    overflow-y: scroll;
  }

  .register .container form::-webkit-scrollbar {
    display: none;
  }

  .register form .fields .input-field {
    width: calc(100% / 2 - 15px);
  }
}

@media (max-width: 550px) {
  .register form .fields .input-field {
    width: 100%;
  }
}
