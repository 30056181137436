.overview-employee-list {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-height: 450px;
  overflow: auto;
}

.overview-employee-list h1 {
  text-align: center;
  color: #343a40;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

.overview-employee-list .add-button {
  padding: 10px 15px;
  background-color: #55ad00;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: auto;
  display: block;
  margin-top: 10px;
  margin-right: 10px;
}

.overview-employee-list .add-button:hover {
  background-color: #3e8100;
}

.overview-employee-list .table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.overview-employee-list .add-button:disabled {
  background-color: #d3d3d3;
  color: #a3a3a3;
  cursor: not-allowed;
}

.overview-employee-list table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.overview-employee-list thead {
  background-color: #eceff6;
  color: #333b46;
}

.overview-employee-list td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.overview-employee-list th {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
  cursor: default;
}

.overview-employee-list tr {
  cursor: pointer;
}

.overview-employee-list tbody tr:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.overview-employee-list td {
  transition: background-color 0.3s;
}

.overview-employee-list .view-details {
  color: #4686e7;
  font-weight: 700;
}

.overview-employee-list .view-details:hover {
  color: #2962b6;
}

.overview-employee-list .modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-employee-list .modal {
  background-color: #fff;
  padding: 50px;
  border-radius: 4px;
  width: 50%;
  max-height: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overview-employee-list .modal h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  font-size: 30px;
}

.overview-employee-list .modal h3 {
  text-align: center;
  font-family: Arial, sans-serif;
  margin-top: 20px;
  font-size: 1.4rem;
}

.overview-employee-list form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
}

.overview-employee-list .label-and-input-pair {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.overview-employee-list label {
  font-size: 1.1rem;
  color: #000;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: #fff;
  width: fit-content;
}

.overview-employee-list .modal select,
.overview-employee-list input {
  padding: 11px 10px;
  font-size: 1.5rem;
  border: 1px #7c7c7c solid;
  border-radius: 5px;
  background: #fff;
  height: 53px;
}

.overview-employee-list .modal button[type="submit"] {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 30%;
}

.overview-employee-list .modal button[type="submit"]:hover {
  background-color: #0056b3;
}

.overview-employee-list .close-x {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.overview-employee-list .close-x:hover {
  color: red;
}

.overview-employee-list .employee-list-header {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
}

.overview-employee-list .employee-list-header h2 {
  font-size: 1.2rem;
  color: #333b46;
  padding-top: 10px;
}

@media (max-width: 991px) {
  .overview-employee-list .modal {
    width: 90%;
    padding: 5px;
  }

  .overview-employee-list .modal {
    width: 90%;
    padding: 5px;
  }

  .overview-employee-list .modal h2 {
    font-size: 25px;
  }

  .overview-employee-list label {
    font-size: 1rem;
  }

  .overview-employee-list .employee-list-header h2 {
    font-size: 1.2rem;
  }
}
