/*STARTING HOME PAGE*/
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700|Lato:300,400,700&display=swap");

body {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #6a6972;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#pricing {
  scroll-margin-top: -100px;
}

#demo {
  scroll-margin-top: 180px;
}

img {
  max-width: 100%;
}

a,
button,
input,
textarea {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a,
a:focus,
input:focus,
textarea:focus,
button:focus,
.navbar-toggler:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #2e2e2e;
  margin: 0px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #6a6972;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 25px;
  font-size: 16px;
  line-height: 48px;
  border-radius: 8px;
  border: 0;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#33c8c1),
    color-stop(50%, #119bd2),
    to(#33c8c1)
  );
  background: linear-gradient(to right, #33c8c1 0%, #119bd2 50%, #33c8c1 100%);
  background-size: 200%;
}

.main-btn:hover {
  color: #fff;
  background-position: right center;
}

@media (max-width: 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/*===== All Section Title Style =====*/
.section-title .line {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fe8464),
    to(#fe6e9a)
  );
  background: linear-gradient(#fe8464 0%, #fe6e9a 100%);
  width: 150px;
  height: 5px;
  margin-bottom: 10px !important;
}

.section-title .sub-title {
  font-size: 18px;
  font-weight: 400;
  color: #361cc1;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .section-title .sub-title {
    font-size: 16px;
  }
}

.section-title .title {
  font-size: 32px;
  padding-top: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title .title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .section-title .title {
    font-size: 24px;
  }
}

.section-title .title span {
  font-weight: 400;
  display: contents;
}

/*===== All Preloader Style =====*/
.preloader {
  /* Body Overlay */
  position: fixed;
  top: 0;
  left: 0;
  display: table;
  height: 100%;
  width: 100%;
  /* Change Background Color */
  background: #fff;
  z-index: 99999;
}

.preloader.hide {
  opacity: 0;
  display: none;
}

.preloader .loader {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.preloader .loader .spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  margin-left: -32px;
  z-index: 18;
  pointer-events: none;
}

.preloader .loader .spinner .spinner-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  -webkit-animation: spinner-linspin 1568.2353ms linear infinite;
  animation: spinner-linspin 1568.2353ms linear infinite;
}

.preloader .loader .spinner .spinner-container .spinner-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  animation: spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .spinner .spinner-container .spinner-rotator .spinner-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  right: 50%;
}

.preloader .loader .spinner .spinner-container .spinner-rotator .spinner-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  left: 50%;
}

.preloader .loader .spinner-circle {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  /* Spinner Color */
  border-color: #361cc1 #361cc1 #e1e1e1;
  border-radius: 50%;
  border-width: 6px;
}

.preloader .loader .spinner-left .spinner-circle {
  left: 0;
  right: -100%;
  border-right-color: #e1e1e1;
  -webkit-animation: spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  animation: spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .spinner-right .spinner-circle {
  left: -100%;
  right: 0;
  border-left-color: #e1e1e1;
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

/* Preloader Animations */
@-webkit-keyframes spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }

  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }

  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg);
  }

  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }

  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg);
  }

  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@keyframes spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }

  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }

  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg);
  }

  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }

  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg);
  }

  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@-webkit-keyframes spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }

  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}

@keyframes spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }

  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}

@-webkit-keyframes right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }

  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

@keyframes right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }

  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

/*===== NAVBAR =====*/
.navbar-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sticky {
  position: fixed;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sticky .navbar {
  padding: 10px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .sticky .navbar {
    padding: 15px 0;
  }
}

.navbar {
  padding: 25px 0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-brand {
  padding: 0;
  height: 50px;
  width: 50px;
}

.navbar-toggler {
  padding: 0;
}

.navbar-toggler .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #fff;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-toggler.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .navbar-nav {
    padding: 20px 30px;
  }
}

.navbar-nav .nav-item {
  margin-right: 45px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .navbar-nav .nav-item {
    margin: 0;
  }
}

.navbar-nav .nav-item a {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 10px 0;
  position: relative;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .navbar-nav .nav-item a {
    display: inline-block;
    padding: 8px 0;
    color: #2e2e2e;
  }
}

.navbar-nav .nav-item a:hover,
.navbar-nav .nav-item a.active {
  color: #fe7a7b;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .navbar-btn {
    position: absolute;
    right: 70px;
  }
}

.navbar-btn .main-btn {
  line-height: 45px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fe8464),
    color-stop(50%, #fe6e9a),
    to(#fe8464)
  );
  background: linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background-size: 200%;
  margin-right: 15px;
}

.navbar-btn .main-btn:hover {
  color: #fff;
  background-position: right center;
}

.sticky .navbar-toggler .toggler-icon {
  background-color: #2e2e2e;
}

.sticky .navbar-nav .nav-item a {
  color: #2e2e2e;
}

.sticky .navbar-nav .nav-item a.active,
.sticky .navbar-nav .nav-item a:hover {
  color: #fe7a7b;
}

/*===== HEADER HERO =====*/
.header-hero {
  position: relative;
  z-index: 5;
  background-position: bottom center;
}

#particles-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.header-hero-content {
  padding-top: 180px;
}

@media (max-width: 767px) {
  .header-hero-content {
    padding-top: 130px;
  }
}

.header-hero-content .header-sub-title {
  font-size: 38px;
  font-weight: 300;
  color: #fff;
}

@media (max-width: 767px) {
  .header-hero-content .header-sub-title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-hero-content .header-sub-title {
    font-size: 30px;
  }
}

.header-hero-content .header-title {
  font-size: 38px;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 767px) {
  .header-hero-content .header-title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-hero-content .header-title {
    font-size: 30px;
  }
}

.header-hero-content .text {
  color: #fff;
  margin-top: 30px;
}

.header-hero-content .main-btn {
  margin-top: 40px;
}

.header-hero-image {
  padding-top: 45px;
}

.header-hero-image img {
  max-width: 650px;
}

@media (max-width: 767px) {
  .header-hero-image img {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .header-hero-image {
    padding-top: 30px;
  }
}

/*===========================
  BRAND css 
===========================*/
@media (max-width: 767px) {
  .brand-logo {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.single-logo {
  padding: 15px 30px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-logo {
    padding: 15px 15px;
  }
}

@media (max-width: 767px) {
  .single-logo {
    padding: 15px 30px;
  }
}

@media (max-width: 767px) {
  .single-logo {
    width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-logo {
    width: 33.33%;
  }
}

.single-logo img {
  max-width: 100%;
  -webkit-filter: grayscale(5);
  filter: grayscale(5);
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-logo:hover img {
  opacity: 1;
  -webkit-filter: none;
  filter: none;
}

/*===========================
  SERVICES css 
===========================*/
.single-services {
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
  box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 50px 30px;
  border: 2px solid transparent;
  border-radius: 8px;
  height: 530px;
}

@media (max-width: 1350px) {
  .single-services {
    height: 530px;
  }
}

@media (max-width: 767px) {
  .single-services {
    padding: 20px 20px 30px;
    height: 500px;
  }
}

.single-services .services-icon {
  display: inline-block;
  position: relative;
}

.single-services .services-icon img {
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.single-services .services-icon .shape-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.single-services .services-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 34px;
  color: #fff;
}

.single-services .services-content .services-title a {
  font-size: 26px;
  font-weight: 700;
  color: #2e2e2e;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-services .services-content .services-title a {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .single-services .services-content .services-title a {
    font-size: 20px;
  }
}

.single-services .services-content .services-title a:hover {
  color: #361cc1;
}

.single-services .services-content .text {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .single-services .services-content .text {
    margin-top: 20px;
  }
}

.single-services .services-content .more {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #fe7a7b;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-services .services-content .more i {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 700;
}

.single-services .services-content .more:hover {
  letter-spacing: 2px;
}

.single-services:hover {
  border-color: #fe7a7b;
}

.single-services:hover .services-icon .shape {
  -webkit-transform: rotate(25deg);
  transform: rotate(25deg);
}

/*===========================
	ABOUT css 
===========================*/
.about-area {
  position: relative;
  z-index: 5;
}

.about-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  z-index: 1;
}

.about-shape-1 img {
  width: 100%;
}

@media (max-width: 767px) {
  .about-shape-1 {
    display: none;
  }
}

.about-shape-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
  height: 100%;
  z-index: -1;
}

.about-shape-2 img {
  width: 100%;
}

@media (max-width: 767px) {
  .about-shape-2 {
    display: none;
  }
}

.about-content {
  max-width: 480px;
}

.about-content .text {
  margin-top: 15px;
}

.about-content .main-btn {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fe8464),
    color-stop(50%, #fe6e9a),
    to(#fe8464)
  );
  background: linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background-size: 200%;
  height: 50px;
  line-height: 50px;
  padding: 0 35px;
  margin-top: 40px;
}

.about-content .main-btn:hover {
  background-position: right center;
}

/*===========================
	VIDEO COUNTER css 
===========================*/
.video-content {
  position: relative;
  padding-bottom: 30px;
}

.video-content .dots {
  position: absolute;
  left: -30px;
  bottom: 0;
}

.video-wrapper {
  margin-right: 15px;
  position: relative;
  -webkit-box-shadow: 0px 13px 46px 0px rgba(113, 113, 113, 0.33);
  box-shadow: 0px 13px 46px 0px rgba(113, 113, 113, 0.33);
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .video-wrapper {
    margin-right: 0;
  }
}

.video-wrapper .video-image img {
  width: 100%;
}

.video-wrapper .video-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(54, 28, 193, 0.2)),
    to(rgba(46, 130, 239, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(54, 28, 193, 0.2) 0%,
    rgba(46, 130, 239, 0.2) 100%
  );
}

.video-wrapper .video-icon a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #fe8464;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.counter-wrapper {
  padding-left: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .counter-wrapper {
    padding-left: 0;
  }
}

.counter-wrapper .counter-content .text {
  margin-top: 35px;
}

.counter-wrapper .single-counter {
  max-width: 155px;
  height: 120px;
  border-radius: 60px;
  position: relative;
  z-index: 5;
  margin-top: 60px;
}

@media (max-width: 767px) {
  .counter-wrapper .single-counter {
    max-width: 100px;
    height: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter {
    max-width: 155px;
    height: 120px;
  }
}

.counter-wrapper .single-counter::before {
  position: absolute;
  content: "";
  width: 155px;
  height: 120px;
  border-radius: 60px;
  top: 0;
  left: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: -1;
}

@media (max-width: 767px) {
  .counter-wrapper .single-counter::before {
    max-width: 100px;
    height: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter::before {
    max-width: 155px;
    height: 120px;
  }
}

.counter-wrapper .single-counter.counter-color-1::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#33c8c1),
    to(#119bd2)
  );
  background: linear-gradient(#33c8c1 0%, #119bd2 100%);
}

.counter-wrapper .single-counter.counter-color-2::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fe8464),
    to(#fe6e9a)
  );
  background: linear-gradient(#fe8464 0%, #fe6e9a 100%);
}

.counter-wrapper .single-counter.counter-color-3::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#361cc1),
    to(#2e82ef)
  );
  background: linear-gradient(#361cc1 0%, #2e82ef 100%);
}

.counter-wrapper .single-counter .count {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 767px) {
  .counter-wrapper .single-counter .count {
    font-size: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter .count {
    font-size: 22px;
  }
}

.counter-wrapper .single-counter .text {
  font-size: 16px;
  color: #fff;
}

@media (max-width: 767px) {
  .counter-wrapper .single-counter .text {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter .text {
    font-size: 16px;
  }
}

/*===========================
  SUBSCRIBE  css 
===========================*/
.subscribe-area {
  padding: 40px 50px 90px;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  border-radius: 10px;
  background-color: #fff;
}

@media (max-width: 767px) {
  .subscribe-area {
    padding: 10px 30px 60px;
  }
}

.subscribe-content .subscribe-title {
  font-size: 35px;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-content .subscribe-title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .subscribe-content .subscribe-title {
    font-size: 24px;
  }
}

.subscribe-content .subscribe-title span {
  font-weight: 400;
}

.subscribe-form {
  position: relative;
}

.subscribe-form input {
  border: 2px solid #e1e1e1;
  height: 65px;
  padding: 0 30px;
  border-radius: 5px;
  width: 100%;
  color: #2e2e2e;
}

.subscribe-form input::-webkit-input-placeholder {
  opacity: 1;
  color: #b9b9b9;
}

.subscribe-form input:-ms-input-placeholder {
  opacity: 1;
  color: #b9b9b9;
}

.subscribe-form input::-ms-input-placeholder {
  opacity: 1;
  color: #b9b9b9;
}

.subscribe-form input::placeholder {
  opacity: 1;
  color: #b9b9b9;
}

.subscribe-form input::-moz-placeholder {
  opacity: 1;
  color: #b9b9b9;
}

.subscribe-form input::-moz-placeholder {
  opacity: 1;
  color: #b9b9b9;
}

.subscribe-form input::-webkit-input-placeholder {
  opacity: 1;
  color: #b9b9b9;
}

.subscribe-form input:focus {
  border-color: #361cc1;
}

.subscribe-form button {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 65px;
  line-height: 65px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fe8464),
    color-stop(50%, #fe6e9a),
    to(#fe8464)
  );
  background: linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background-size: 200%;
}

@media (max-width: 767px) {
  .subscribe-form button {
    position: relative;
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form button {
    position: absolute;
    width: auto;
    margin-top: 0;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.subscribe-form button:hover {
  color: #fff;
  background-position: right center;
}

/*===========================
	FOOTER  css 
===========================*/
.footer-area {
  background-position: top center;
  position: relative;
  z-index: 5;
}

.footer-area::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: top center;
  z-index: -1;
  background-size: cover;
}

@media only screen and (min-width: 1921px) {
  .footer-area::before {
    height: 1120px;
  }
}

#particles-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.footer-widget {
  padding-top: 50px;
}

.footer-about .logo img {
  width: 160px;
}

.footer-about .text {
  color: #fff;
  line-height: 30px;
  margin-top: 30px;
}

.footer-about .social {
  margin-top: 40px;
}

.footer-about .social li {
  display: inline-block;
  margin-right: 30px;
}

.footer-about .social li a {
  font-size: 22px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.footer-about .social li a:hover {
  color: #fe8464;
}

.footer-title .title {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.link-wrapper {
  padding: 0 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .link-wrapper {
    padding: 0px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .link-wrapper {
    padding: 0;
    padding-right: 50px;
  }
}

.link-wrapper .link {
  padding-top: 20px;
}

.link-wrapper .link li {
  margin-top: 20px;
}

.link-wrapper .link li a {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.link-wrapper .link li a:hover {
  color: #fe8464;
}

.footer-contact .contact {
  padding-top: 20px;
}

.footer-contact .contact li {
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
}

.footer-copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 15px;
  padding-bottom: 30px;
}

.copyright-content {
  padding-top: 15px;
  text-align: center;
}

.copyright-content p {
  color: #fff;
}

.copyright-content a {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.copyright-content a:hover {
  color: #fe8464;
}

/*===== BACK TO TOP =====*/
.back-to-top {
  font-size: 20px;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fe8464),
    color-stop(50%, #fe6e9a),
    to(#fe8464)
  );
  background: linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background-size: 200%;
  text-align: center;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.back-to-top:hover {
  color: #fff;
  background-position: right center;
}

/* ======================
    DEFAULT CSS
========================= */
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

/*Pricing*/
.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  position: relative;
  z-index: 10;
}

.card {
  background: #fff;
  color: hsl(233, 13%, 49%);
  border-radius: 0.8rem;
}

.cards .card.active {
  background: linear-gradient(
    135deg,
    rgba(48, 104, 227, 1) 0%,
    rgb(51, 65, 209) 100%
  );
  color: #fff;
  display: flex;
  align-items: center;
  transform: scale(1.1);
  z-index: 1;
}

.pricing-text {
  margin: 2.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.pack {
  font-size: 1.5rem;
  font-weight: bold;
}

.price {
  font-size: 3rem;
  color: hsl(232, 13%, 33%);
  padding-bottom: 2rem;
}

.bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.582);
  padding: 1rem 0;
}

.card.active .price {
  color: #fff;
}

.pricing-btn {
  margin-top: 1rem;
  height: 2.6rem;
  width: 13.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(48, 104, 227, 1) 0%,
    rgba(51, 65, 209, 1) 100%
  );
  color: #fff;
  outline: none;
  border: 0;
  font-weight: bold;
  cursor: pointer;
}

.active-btn {
  margin-top: 1rem;
  height: 2.6rem;
  width: 13.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgb(65, 121, 243) 0%,
    rgb(56, 72, 252) 100%
  );
  color: #fff;
  outline: none;
  border: 0;
  font-weight: bold;
  cursor: pointer;
}

.about-title {
  display: flex;
  justify-content: center;
  padding-top: 70px;
}
/*Carousel*/
.static-features-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.static-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2.5rem;
  justify-items: center;
}

.static-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2.5rem 2rem;
  border-radius: 12px;
  background: white;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  border-top: 4px solid transparent;
  z-index: 10;
  width: 100%;
}

@media (max-width: 1400px) and (min-width: 991px) {
  .static-features-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .static-feature:nth-child(3),
  .static-feature:nth-child(4) {
    grid-column: span 1;
  }
}

.static-feature:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
}

.feature-logo-container {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 50%;
  background: #ffffff;
}

.feature-logo {
  height: 50px;
  width: auto;
  filter: brightness(1.1);
  filter: brightness(0) saturate(100%) invert(32%) sepia(89%) saturate(1352%)
    hue-rotate(209deg) brightness(97%) contrast(96%);
}

/* Colored logo variants */
.guaranteed-logo {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
}

.results-logo {
  filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
    brightness(91%) contrast(135%);
}

.accessible-logo {
  filter: invert(48%) sepia(89%) saturate(7488%) hue-rotate(236deg)
    brightness(98%) contrast(94%);
}

.specialized-logo {
  filter: invert(75%) sepia(63%) saturate(576%) hue-rotate(358deg)
    brightness(102%) contrast(102%);
}

.feature-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #2c3e50;
  position: relative;
  padding-bottom: 0.5rem;
}

.feature-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: currentColor;
}

.feature-text {
  font-size: 1.05rem;
  line-height: 1.7;
  color: #555;
}

.feature-text p {
  margin-bottom: 0.75rem;
}

.feature-text strong {
  color: #2c3e50;
}

@media (max-width: 280px) {
  .pricing-text {
    margin: 1rem;
  }

  h1 {
    font-size: 1.2rem;
  }

  .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80px;
  }

  .cards {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .card {
    transform: scale(0.8);
    margin-bottom: 1rem;
  }

  .cards .card.active {
    transform: scale(0.8);
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .pricing-text {
    margin: 20px;
  }

  .cards {
    display: flex;
    flex-direction: column;
  }

  .card {
    margin-bottom: 1rem;
  }

  .cards .card.active {
    transform: scale(1);
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .cards {
    display: flex;
    flex-direction: column;
  }

  .card {
    margin-bottom: 1rem;
  }

  .cards .card.active {
    transform: scale(1);
  }
}

@media (min-width: 414px) and (max-width: 768px) {
  .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .cards .card.active {
    transform: scale(1);
  }
}

@media (min-width: 768px) and (max-width: 1046px) {
  .cards {
    display: flex;
  }

  .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .cards .card.active {
    transform: scale(1);
  }
}

/*ENDING HOME PAGE*/
