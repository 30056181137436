.employee-detail-earnings {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-detail-earnings .card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  transition: transform 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.employee-detail-earnings .header {
  color: #333b46;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: left;
}

.employee-detail-earnings .selection {
  flex: 1;
  margin-right: 20px;
}

.employee-detail-earnings .results {
  flex: 2;
  text-align: left;
  font-weight: bold;
  font-size: 0.8rem;
  width: 100%;
}

.employee-detail-earnings .timeframe-selector {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.employee-detail-earnings .timeframe-selector label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.2s;
}

.employee-detail-earnings .timeframe-selector label:hover {
  color: #007bff;
}

.employee-detail-earnings .timeframe-selector input[type="radio"] {
  margin-right: 10px;
}

.employee-detail-earnings .custom-date-picker,
.employee-detail-earnings .custom-single-date-picker {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.employee-detail-earnings .date-input {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.employee-detail-earnings .date-input:focus {
  outline: none;
  border-color: #007bff;
}

.employee-detail-earnings .results {
  padding-left: 20px;
  border-left: 2px solid #e0e0e0;
}

.employee-detail-earnings .earnings-display {
  text-align: left;
  font-weight: bold;
  font-size: 1.25rem;
  color: #28a745;
}

.employee-detail-earnings .earnings-title {
  font-size: 1rem;
  color: #616a77;
  margin: 10px 0;
}

.employee-detail-earnings .salary-message {
  margin: 80px 0;
  font-size: 1.2em;
  text-align: center;
}

.employee-detail-earnings .salary-message .hourly {
  font-weight: bold;
}

.employee-detail-earnings .attendance-table-container {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: auto;
  margin-top: 20px;
  width: 100%;
}

.employee-detail-earnings .attendance-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.employee-detail-earnings .attendance-table th,
.employee-detail-earnings .attendance-table td {
  padding: 10px;
  border: 1px solid #ced4da;
  text-align: left;
  word-wrap: break-word;
}

.employee-detail-earnings .attendance-table th {
  background-color: #f8f9fa;
  white-space: nowrap;
}

.employee-detail-earnings .attendance-table td {
  white-space: nowrap;
}

.employee-detail-earnings .signature-img {
  width: 50px;
  height: auto;
  border-radius: 4px;
}

@media (max-width: 1541px) {
  .employee-detail-earnings {
    flex-direction: column;
    width: 100%;
  }

  .employee-detail-earnings .card {
    flex-direction: column;
  }

  .employee-detail-earnings .selection {
    margin-right: 0;
  }

  .employee-detail-earnings .results {
    border-left: none;
    padding-left: 0;
  }
}
