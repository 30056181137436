.disclaimer-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disclaimer-page .banner {
  z-index: 1;
}

.disclaimer-page .banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.disclaimer-page .disclaimer-page-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  z-index: 2;
  padding: 20px;
  width: 100%;
}

.disclaimer-page .logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  z-index: 10;
}

.disclaimer-page .disclaimer-container {
  width: 60%;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 5;
  margin-top: 100px;
  margin-bottom: 100px;
}

.disclaimer-page .disclaimer-container header {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  position: relative;
}

.disclaimer-page .disclaimer-container header::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 3px;
  width: 40px;
  border-radius: 4px;
  background-color: #4070f4;
}

.disclaimer-page .disclaimer-container h2 {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-top: 25px;
}

.disclaimer-page .disclaimer-container p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-top: 10px;
}

.disclaimer-page .disclaimer-container a {
  color: #4070f4;
  font-weight: bold;
  text-decoration: none;
}

.disclaimer-page .disclaimer-container a:hover {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .disclaimer-page .disclaimer-container {
    width: 100%;
    margin-top: 20%;
  }
}
